<template>
  <div class="pageRoot">
    <SwitchModule />
  </div>
</template>

<script>
import SwitchModule from '@/modules/Switch'

export default {
  components: {
    SwitchModule,
  },
}
</script>
